body {
  margin: 0;
  font-family: 'Grenze Gotisch', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #009688; 
  border-radius: 50px;
}

code {
  font-family: 'Grenze Gotisch', cursive;
}
